export const fetchTableData = (
  pagination,
  sorter,
  tableData,
  setTableDataAct,
  updateSavedFilterViewParameters,
  isSearchResultsTable = false,
  fetchOrders = () => {}
) => {
  const { current: nextPage } = pagination;

  if (nextPage === tableData?.selectedPage) {
    const columnIsChanged = sorter.columnKey !== tableData?.sorter?.columnKey;
    const sortDirectionIsChanged = sorter.order !== tableData?.sorter?.order;
    if (columnIsChanged || sortDirectionIsChanged) {
      pagination.current = 1;
    }
  }

  if (isSearchResultsTable) {
    fetchOrders(pagination.current, sorter);
  } else {
    setTableDataAct(pagination.current, sorter);
  }

  if (updateSavedFilterViewParameters) {
    // Note: We need to maintain the last visited paging & sorting for each saved filter view.
    updateSavedFilterViewParameters(pagination.current, sorter);
  }

  window.scrollTo(0, 0);
};
